html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquotebefore, blockquoteafter, qbefore, qafter {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  text-shadow: 1px 1px 1px #00000001;
  text-rendering: optimizelegibility !important;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)) );
  }
}

body {
  font-family: Space Mono, monospace;
}

a {
  color: #ee659f;
  transition: all .3s;
}

a:hover {
  color: #2f2f2f;
}

strong {
  font-weight: 700;
}

img {
  width: 100%;
  height: auto;
}

h1 {
  letter-spacing: -.03em;
  font-size: 3em;
  line-height: 110%;
}

h2 {
  letter-spacing: -.03em;
  font-size: 2em;
  line-height: 110%;
}

p {
  margin-bottom: 3em;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

p.caption {
  color: #bdbdbd;
  text-align: center;
  font-style: italic;
}

span.icon {
  color: icon-primary;
  font-size: 50px;
  line-height: 1.3;
}

footer, section {
  margin: 0;
}

main {
  width: 100%;
}

.site__header {
  height: 175vh;
  background-color: #f8f201;
  background-image: url("table.c7eaf0bb.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 825px 788px;
  border-bottom: 50px solid #f8f201;
  gap: 50px;
  padding: 0 50px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 769px) {
  .site__header {
    padding: 0;
  }
}

.site__header .logo {
  width: 100%;
  height: auto;
  max-width: 420px;
  opacity: .5;
  margin: 0 auto;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
}

.site__header .icon {
  opacity: .15;
  margin: 0 auto;
  animation: 2s infinite bounce;
  display: inline-block;
  position: relative;
  top: 50px;
  left: 0;
  right: 0;
}

.site__marquee {
  border-bottom: 5px solid;
  text-transform: uppercase;
  --gap: 1rem;
  -webkit-user-select: none;
  user-select: none;
  gap: var(--gap);
  border-width: 5px;
  border-image-source: linear-gradient(245.27deg, #ff5c00 -15.25%, #ee4f8e 13.02%, #8728ff 37.26%, #52b0fa 70.72%, #00ff0a 95.53%);
  border-image-slice: 1;
  padding: 1.5em;
  font-size: 1.5em;
  display: flex;
  position: relative;
  overflow: hidden;
}

.site__marquee__content {
  justify-content: space-around;
  gap: var(--gap);
  min-width: 25%;
  flex-shrink: 0;
  animation: 10s linear infinite scroll;
  display: flex;
}

.site__introduction--wrapper {
  width: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 50px;
  padding: 3em;
  display: grid;
}

.site__introduction--wrapper h1 {
  opacity: 0;
  grid-area: 1 / span 3;
}

.site__introduction--wrapper p {
  color: #333200;
  font-size: 1.3em;
  line-height: 1.6;
}

.site__introduction--wrapper section {
  grid-area: 2 / 2 / auto / span 2;
}

.site__kbdmag--wrapper {
  width: 100%;
  background: #000;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 50px;
  padding: 3em;
  display: grid;
}

.site__kbdmag--wrapper h2 {
  color: #fff;
  opacity: 0;
  grid-area: 1 / 1 / auto / span 4;
}

.site__kbdmag--wrapper p {
  color: #fff;
  font-size: 1.3em;
  line-height: 1.6;
}

.site__kbdmag--wrapper section {
  grid-area: 2 / 1 / auto / span 4;
}

.site__kbdmag--issue {
  grid-area: 3 / 1 / auto / span 4;
  grid-template-rows: minmax(200px, 1fr);
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2.5rem;
  list-style: none;
  display: grid;
}

.site__kbdmag--issue li {
  color: #fff;
  background: #232323;
  border-radius: 10px;
  padding: 20px;
}

.site__kbdmag--issue li:first-of-type {
  background: none;
  padding: 20px 0 0;
}

.site__insta--wrapper {
  width: 100%;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 50px;
  padding: 3em;
  display: grid;
}

.site__insta--wrapper h2 {
  opacity: 0;
  grid-area: 1 / 1 / auto / span 4;
}

.site__insta--wrapper p {
  font-size: 1.3em;
  line-height: 1.6;
}

.site__insta--wrapper section {
  grid-area: 2 / 3 / auto / span 2;
}

.site__insta--visual {
  opacity: 0;
  grid-area: 2 / 1 / auto / span 2;
  padding-right: 3em;
}

.site__footer {
  width: 100%;
  background-color: #f8f201;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 50px;
  padding: 3em;
  display: grid;
}

.site__footer .copyright {
  text-align: right;
}

/*# sourceMappingURL=index.a1cc8456.css.map */
