
@import "_reset"
@import "_animations"

background = #F8F201
text-body = #333200
text-caption = #BDBDBD
text-link = #EE659F
text-link-hover = #2F2F2F

body
    font-family: 'Space Mono', monospace

a
    color: text-link
    transition: 0.3s
    &:hover
        color: text-link-hover

strong
    font-weight: 700

img
    width: 100%
    height: auto
h1
    font-size: 3em;
    line-height: 110%;
    letter-spacing: -0.03em;
h2
    font-size: 2em;
    line-height: 110%;
    letter-spacing: -0.03em;
p
    font-size: 14px
    font-weight: 400
    line-height: 1.3
    margin-bottom: 3em

    &.caption
        color: text-caption
        text-align: center
        font-style: italic

span
    &.icon
        color: icon-primary
        font-size: 50
        line-height: 1.3

footer,
section
    margin: 0

main
    width: 100%

.site
    &__header
        @media screen and (min-width: 769px)
            padding: 0
        display: flex
        background-color: background
        border-bottom: 50px solid background
        height: 175vh
        gap: 50px
        padding: 0 50px
        background-image: url('../img/graphic/table.svg')
        background-size: 825px 788px
        background-repeat: no-repeat
        background-position: center bottom
        position: relative
        

        .logo
            width: 100%
            height: auto
            margin: 0 auto
            max-width: 420px
            position: absolute
            left: 0
            right: 0
            top: -50px
            opacity: 0.5
        
        .icon
            animation: bounce 2s infinite
            display: inline-block
            margin: 0 auto
            opacity: 0.15
            position: relative
            top: 50px
            left: 0
            right: 0
            
    &__marquee
        border-bottom: 5px solid;
        border-image-slice: 1;
        border-width: 5px;
        border-image-source: linear-gradient(245.27deg, #FF5C00 -15.25%, #EE4F8E 13.02%, #8728FF 37.26%, #52B0FA 70.72%, #00FF0A 95.53%);
        text-transform: uppercase
        font-size: 1.5em
        padding: 1.5em
        --gap: 1rem;
        position: relative;
        display: flex;
        overflow: hidden;
        user-select: none;
        gap: var(--gap);
        &__content
            animation: scroll 10s linear infinite;
            flex-shrink: 0;
            display: flex;
            justify-content: space-around;
            gap: var(--gap);
            min-width: 25%;
            
    &__introduction
        &--wrapper
            display: grid
            grid-template-columns: 1fr 1fr 1fr
            grid-template-rows: 1fr
            row-gap: 50px
            padding: 3em
            width: 100%
            
            h1
                grid-row: 1
                grid-column: span 3
                opacity: 0
            p
                color: text-body
                font-size: 1.3em
                line-height: 1.6
            section
                grid-row: 2
                grid-column: 2 / span 2

    &__kbdmag
        &--wrapper
            background: #000
            display: grid
            grid-template-columns: 1fr 1fr 1fr 1fr
            grid-template-rows: 1fr 1fr
            row-gap: 50px
            padding: 3em
            width: 100%
            
            h2
                color: #fff
                grid-row: 1
                grid-column: 1 / span 4
                opacity: 0
            p
                color: #fff
                font-size: 1.3em
                line-height: 1.6
            section
                grid-row: 2
                grid-column: 1 / span 4
                
        &--issue
            display: grid
            grid-row: 3
            grid-column: 1 / span 4
            grid-template-columns: repeat(4,minmax(0,1fr));
            grid-template-rows: minmax(200px,1fr)
            gap: 2.5rem;
            list-style: none
            li
                color: #fff
                background: #232323
                border-radius: 10px
                padding: 20px
                &:first-of-type
                    background: none
                    padding: 20px 0 0 0 

    
    &__insta
        &--wrapper
            display: grid
            grid-template-columns: 1fr 1fr 1fr 1fr
            grid-template-rows: auto 1fr
            row-gap: 50px
            padding: 3em
            width: 100%
            
            h2
                grid-row: 1
                grid-column: 1 / span 4
                opacity: 0
            p
                font-size: 1.3em
                line-height: 1.6
            section
                grid-row: 2
                grid-column: 3 / span 2
        &--visual
            opacity: 0
            grid-row: 2
            grid-column: 1 / span 2
            padding-right: 3em
    &__footer
        background-color: background
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr
        row-gap: 50px
        padding: 3em
        width: 100%
        .copyright
            text-align: right
